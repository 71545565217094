import { useEffect, useRef, useState, type ReactNode } from 'react';

import { Tooltip } from '@/components/shared/Tooltip';
import type { Nullable } from '@/types/shared';

import style from './AddressLines.module.sass';

type AddressLinesProps = {
  addressLine?: Nullable<string>;
  placeholder?: ReactNode;
};

const DEFAULT_MAX_LINES = 3;

export const AddressLines = ({
  addressLine,
  placeholder,
}: AddressLinesProps) => {
  const lineRefs = useRef<(HTMLParagraphElement | null)[]>([]);
  const [truncatedStates, setTruncatedStates] = useState<boolean[]>([]);

  const addressToLines = (string: Nullable<string>) => {
    return string?.split(',').filter(Boolean);
  };

  const lines = addressToLines(addressLine);

  useEffect(() => {
    if (truncatedStates.length > 0) {
      return;
    }

    const checkTruncation = () => {
      const newTruncatedStates = lineRefs?.current?.map((element) => {
        if (element) {
          return element.scrollWidth > element.clientWidth;
        }

        return false;
      }) ?? [];

      setTruncatedStates(newTruncatedStates);
    };

    checkTruncation();
    window.addEventListener('resize', checkTruncation);

    return () => window.removeEventListener('resize', checkTruncation);
  }, [lines, truncatedStates.length]);

  if (!addressLine || !lines) {
    return placeholder ? <>{placeholder}</> : null;
  }

  const isAddressNotSuperLongButStillLongEnough = lines.length > 3;
  const isAnyOfLinesTruncated = truncatedStates.some(Boolean);

  const renderLine = (
    line: string,
    index: number,
    shouldShowDots?: boolean,
  ) => {
    const shouldRenderDots = index + 1 === DEFAULT_MAX_LINES && shouldShowDots;

    const renderDots = () => {
      if (shouldShowDots) {
        return (
          <>
            {' '}
            {shouldRenderDots && '...'}
          </>
        );
      }
    };

    return (
      <p
        className={style.line}
        key={index}
        ref={
          (el) => {
            if (lineRefs.current) {
              lineRefs.current[index] = el;
            }
          }
        }>
        {line.trim()}
        {renderDots()}
      </p>
    );
  };

  const renderTooltipLine = (line: string, index: number) => {
    return (
      <p className={style.tooltipLine} key={index}>
        {line.trim()}
      </p>
    );
  };

  const shouldWeRenderTooltip = isAddressNotSuperLongButStillLongEnough || isAnyOfLinesTruncated;

  if (shouldWeRenderTooltip) {
    const slicedLines = lines.slice(0, 3);

    const shouldRenderDots = lines.length > DEFAULT_MAX_LINES;

    return (
      <Tooltip
        content={
          <div>
            {lines.map(renderTooltipLine)}
          </div>
        }>
        <div>
          {slicedLines.map((line, index) => renderLine(line, index, shouldRenderDots))}
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      {lines.map((line, index) => renderLine(line, index))}
    </>
  );
};
