import { QueryFunctionContext } from 'react-query';
import produce from 'immer';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries';
import { UseEnforcementPoliciesParams } from '@/hooks/enforcementPolicy/useEnforcementPolicies';
import { INFRINGEMENT_TYPE_ID, IS_TEST_PURCHASE_REQUIRED, POLICY_TYPE_ID, SUBDOMAIN_ID } from '@/resources/queryParams';
import type { EnforcementPolicyType } from '@/types/enforcementPolicyType';
import type { EnforcementInfringementType } from '@/types/enforcementInfringementType';
import { EnforcementSubdomain } from '@/types/enforcement';
import { EnforcementChannel } from '@/types/enforcementPolicyDefinition';
import type { EnforcementPoliciesFilterParams, EnforcementPoliciesMeta, EnforcementPolicy } from '@/types/enforcementPolicy';
import { Nullable } from '@/types/shared';

export type EnforcementPolicyIncludedRecord =
  | EnforcementSubdomain
  | EnforcementChannel
  | EnforcementPolicyType
  | EnforcementInfringementType;

export type EnforcementPoliciesQueryKey = [
  string, UseEnforcementPoliciesParams | undefined
];

export const fetchEnforcementPolicies = async (
  context: QueryFunctionContext<EnforcementPoliciesQueryKey>,
) => {
  const [, params = {}] = context.queryKey;

  const {
    pagination,
    sort,
    filter,
  } = params;

  const page = pagination && {
    size: pagination.perPage,
    number: pagination.page,
  };

  const filterParams = buildEnforcementPoliciesFilterParams(filter);

  const response = await Api.get<
    EnforcementPolicy[],
    EnforcementPolicyIncludedRecord,
    EnforcementPoliciesMeta
  >('/api/enforcement_policies', {
    params: {
      filter: filterParams,
      page,
      sort: sort?.sort,
    },
  });

  const parsedEnforcementPolicies = linkCollectionRelationships(response);

  return {
    enforcementPolicies: parsedEnforcementPolicies,
    meta: response.meta,
  };
};

type EnforcementPoliciesFilterQuery = EnforcementPoliciesFilterParams & {
  SUBDOMAIN_ID: never;
  'policy_definition.subdomain.id': Nullable<string>;
  POLICY_TYPE_ID: never;
  'policy_definition.policy_type.id': Nullable<string>;
  INFRINGEMENT_TYPE_ID: never;
  'policy_definition.infringement_type.id': Nullable<string>;
  IS_TEST_PURCHASE_REQUIRED: never;
  'policy_definition.is_test_purchase_required': Nullable<string>;
};

export const buildEnforcementPoliciesFilterParams = (
  filter: EnforcementPoliciesFilterParams | undefined,
) => {
  if (!filter) return filter;

  return produce<
    EnforcementPoliciesFilterParams, EnforcementPoliciesFilterQuery
  >(filter, (draft) => {
    draft['policy_definition.subdomain.id'] = draft[SUBDOMAIN_ID];
    delete draft[SUBDOMAIN_ID];

    draft['policy_definition.policy_type.id'] = draft[POLICY_TYPE_ID];
    delete draft[POLICY_TYPE_ID];

    draft['policy_definition.infringement_type.id'] = draft[INFRINGEMENT_TYPE_ID];
    delete draft[INFRINGEMENT_TYPE_ID];

    draft['policy_definition.is_test_purchase_required'] = draft[IS_TEST_PURCHASE_REQUIRED];
    delete draft[IS_TEST_PURCHASE_REQUIRED];
  });
};

