import { useDataQuery } from '@/hooks/shared';
import { fetchEnforcementPolicies } from '@/queries/enforcementPolicy';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { EnforcementPoliciesFilterParams } from '@/types/enforcementPolicy';
import { PaginationConfig, SortConfig } from '@/types/shared';

export type UseEnforcementPoliciesParams = {
  filter?: EnforcementPoliciesFilterParams;
  pagination?: PaginationConfig;
  sort?: SortConfig;
}

type UseEnforcementPoliciesOptions = {
  enabled?: boolean;
}

export const useEnforcementPolicies = (
  params?: UseEnforcementPoliciesParams,
  options: UseEnforcementPoliciesOptions = {},
) => {
  const {
    data: queryData,
    ...rest
  } = useDataQuery(
    [QUERY_KEYS.enforcementPolicies, params],
    fetchEnforcementPolicies,
    {
      enabled: options?.enabled,
    },
  );

  return {
    ...queryData,
    ...rest,
  };
};
