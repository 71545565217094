import { type TFunction } from 'i18next';

import { absFloor } from '@/utils/math';

import {
  formatTime,
  ensureIsDate,
  midnight,
  isNotValidDate,
} from './index';

type FormatRelativeDateOptions = {
  showTime?: boolean;
  style?: 'long' | 'short' | 'narrow';
}

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 30.4 * DAY; // average length of a month in days
const YEAR = 365 * DAY;

const formatTextWithTime = (
  text: string,
  date: Date,
  t: TFunction,
  locale: string,
  opts: FormatRelativeDateOptions,
) => {
  if (!opts.showTime) {
    return text;
  }

  return t('common:time.textAndTime', { text: text, time: formatTime(date, locale) });
};

const getRelativeDateText = (
  date: Date,
  t: TFunction,
  locale: string,
  {
    style = 'long',
    ...opts
  }: FormatRelativeDateOptions,
) => {
  const formatter = new Intl.RelativeTimeFormat(locale, { style: style });
  const now = new Date();

  const diffDays = (+midnight(date) - +now) / DAY;
  const absDiffDays = absFloor(diffDays);

  if (absDiffDays === 0) {
    return formatTextWithTime(t('common:time.today'), date, t, locale, opts);
  }

  if (absDiffDays === -1) {
    return formatTextWithTime(t('common:time.yesterday'), date, t, locale, opts);
  }

  if (absDiffDays === 1) {
    return formatTextWithTime(t('common:time.tomorrow'), date, t, locale, opts);
  }

  const diff = (+date) - (+now);
  const absDiff = Math.abs(diff);

  if (absDiff < WEEK) {
    return formatter.format(absFloor(absDiffDays), 'days');
  }

  if (absDiff < WEEK * 5) {
    return formatter.format(absFloor(diff / WEEK), 'weeks');
  }

  if (absDiff < YEAR) {
    return formatter.format(absFloor(diff / MONTH), 'months');
  }

  return formatter.format(absFloor(diff / YEAR), 'years');
};

export const formatRelativeDate = (
  param: string | Date | undefined,
  t: TFunction,
  locale: string,
  opts: FormatRelativeDateOptions = {},
) => {
  if (!param) {
    return param;
  }

  const date = ensureIsDate(param);

  if (isNotValidDate(date)) {
    return '';
  }

  return getRelativeDateText(date, t, locale, opts);
};
