import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { EnforcementIncludedRecord } from '@/queries/enforcement/fetchEnforcement';
import { linkCollectionRelationships } from '@/utils/queries';
import { EnforcementPolicy } from '@/types/enforcementPolicy';
import { EnforcementPolicyDefinition } from '@/types/enforcementPolicyDefinition';

export type EnforcementPolicyQueryKey = [
  string, string | undefined
];

export type EnforcementPolicyIncludedRecord = EnforcementPolicyDefinition

export const fetchEnforcementPolicy = async (
  context: QueryFunctionContext<EnforcementPolicyQueryKey>,
) => {
  const [, enforcementPolicyId = {}] = context.queryKey;

  const response = await Api.get<
    EnforcementPolicy, EnforcementIncludedRecord
  >(`/api/enforcement_policies/${enforcementPolicyId}`);

  const enforcementPolicy = linkCollectionRelationships(response);

  return enforcementPolicy;
};
