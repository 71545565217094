import { TFunction } from 'i18next';

type ActivableRecord = {
  attributes: {
    isActive?: boolean;
  };
};

export const checkIsActive = (record: ActivableRecord | undefined) => {
  return record?.attributes.isActive;
};

type GetTooltipMessageForUncreatableAction = {
  isTrademarkAddedToSubdomain: boolean;
  hasActiveLoa: boolean;
  hasSubdomainActivePolicyEnforcement: boolean;
  t: TFunction;
};

export const getTooltipMessageForUncreatableAction = ({
  hasActiveLoa,
  isTrademarkAddedToSubdomain,
  hasSubdomainActivePolicyEnforcement,
  t,
}: GetTooltipMessageForUncreatableAction) => {
  if (!hasActiveLoa) {
    return t('store:listingsList.noActiveLoa');
  }

  if (!isTrademarkAddedToSubdomain) {
    return t('store:listingsList.noTrademarksForChannel');
  }

  if (!hasSubdomainActivePolicyEnforcement) {
    return t('store:listingsList.noActivePolicyEnforcement');
  }
};
