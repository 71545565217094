import { createContext, useContext } from 'react';

import { RouteParam } from '@/types/shared';

export type RoutePathContextValue = {
  getInstanceUsersRoute: () => string;
  getInstanceUserRoute: (id: RouteParam) => string;
  getInstanceUserCreateRoute: () => string;
  getInstanceUserEditRoute: (id: RouteParam) => string;
  getInstanceUserDeleteRoute: (id: RouteParam) => string;

  getInstanceBotsRoute: () => string;
  getInstanceBotRoute: (id: RouteParam) => string;
  getInstanceBotCreateRoute: () => string;
  getInstanceBotCreateMultipleRoute: () => string;
  getInstanceBotEditRoute: (id: RouteParam) => string;
  getInstanceBotDeleteRoute: (id: RouteParam) => string;
  getInstanceBotRunRoute: (id: RouteParam) => string;

  getInstanceTrademarksRoute: (companyId?: RouteParam) => string;
  getInstanceTrademarkRoute: (companyId: RouteParam, id: RouteParam) => string;
  getInstanceTrademarkCreateRoute: (companyId?: RouteParam) => string;
  getInstanceTrademarkEditRoute: (companyId: RouteParam, id: RouteParam) => string;
  getInstanceTrademarkDeleteRoute: (companyId: RouteParam, id: RouteParam) => string;

  getInstanceKeywordsRoute: () => string;
  getInstanceKeywordRoute: (id: RouteParam) => string;
  getInstanceKeywordCreateMultipleRoute: () => string;
  getInstanceKeywordEditRoute: (id: RouteParam) => string;
  getInstanceKeywordDeleteRoute: (id: RouteParam) => string;

  getInstanceCompanyRoute: (id: RouteParam) => string;
  getInstanceCompanyEditRoute: (id: RouteParam) => string;
  getInstanceCompanyDeleteRoute: (id: RouteParam) => string;
  getInstanceEnforcementPoliciesRoute: () => string;
  getInstanceEnforcementPolicyRoute: (id: RouteParam) => string;

  getInstanceLoasRoute: () => string;
  getInstanceLoaRoute: (id: RouteParam) => string;
  getInstanceLoaDownloadToSignRoute: (id: RouteParam) => string;
  getInstanceLoaUploadSignedRoute: (id: RouteParam) => string;
  getInstanceLoaCreateRoute: () => string;
  getInstanceLoaEditRoute: (id: RouteParam) => string;
  getInstanceLoaDuplicateRoute: (id: RouteParam) => string;

  getInstanceBrandsRoute: () => string;
  getInstanceBrandRoute: (id: RouteParam, overwrittenCompanyId?: RouteParam) => string;
  getInstanceBrandCreateRoute: () => string;
  getInstanceBrandEditRoute: (id: RouteParam) => string;
  getInstanceBrandDeleteRoute: (id: RouteParam) => string;
  getInstanceCreateEnforcementPolicyRoute: () => string;
  getInstanceEditEnforcementPolicyRoute: (id: RouteParam) => string;
};

export const ROUTE_PATH_CONTEXT_DEFAULT_STATE = {
  getInstanceUsersRoute: () => '',
  getInstanceUserRoute: () => '',
  getInstanceUserCreateRoute: () => '',
  getInstanceUserEditRoute: () => '',
  getInstanceUserDeleteRoute: () => '',

  getInstanceBotsRoute: () => '',
  getInstanceBotRoute: () => '',
  getInstanceBotCreateRoute: () => '',
  getInstanceBotCreateMultipleRoute: () => '',
  getInstanceBotEditRoute: () => '',
  getInstanceBotDeleteRoute: () => '',
  getInstanceBotRunRoute: () => '',

  getInstanceTrademarksRoute: () => '',
  getInstanceTrademarkRoute: () => '',
  getInstanceTrademarkCreateRoute: () => '',
  getInstanceTrademarkEditRoute: () => '',
  getInstanceTrademarkDeleteRoute: () => '',

  getInstanceKeywordsRoute: () => '',
  getInstanceKeywordRoute: () => '',
  getInstanceKeywordCreateMultipleRoute: () => '',
  getInstanceKeywordEditRoute: () => '',
  getInstanceKeywordDeleteRoute: () => '',

  getInstanceCompanyRoute: () => '',
  getInstanceCompanyEditRoute: () => '',
  getInstanceCompanyDeleteRoute: () => '',

  getInstanceLoasRoute: () => '',
  getInstanceLoaRoute: () => '',
  getInstanceLoaDownloadToSignRoute: () => '',
  getInstanceLoaUploadSignedRoute: () => '',
  getInstanceLoaCreateRoute: () => '',
  getInstanceLoaEditRoute: () => '',
  getInstanceLoaDuplicateRoute: () => '',

  getInstanceBrandsRoute: () => '',
  getInstanceBrandRoute: () => '',
  getInstanceBrandCreateRoute: () => '',
  getInstanceBrandEditRoute: () => '',
  getInstanceBrandDeleteRoute: () => '',

  getInstanceEnforcementPoliciesRoute: () => '',
  getInstanceEnforcementPolicyRoute: () => '',
  getInstanceCreateEnforcementPolicyRoute: () => '',
  getInstanceEditEnforcementPolicyRoute: () => '',
};

export const RoutePathContext =
  createContext<RoutePathContextValue>(ROUTE_PATH_CONTEXT_DEFAULT_STATE);

export const useRoutePathContext = () => {
  const contextValue = useContext(RoutePathContext);

  if (!contextValue) {
    throw new Error('RoutePathContext not found');
  }

  return contextValue;
};
